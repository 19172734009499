<template>
    <y-dropdown
        v-if="actions && Object.keys(actions).length"
        ref="MassActionDropdown"
        :disabled="!hasSelected"
        position="bottom-left"
    >
        <template slot="toggle">
            <y-button :disabled="!hasSelected">
                {{ $t('button.mass_actions') }}
            </y-button>
        </template>

        <a
            v-for="button in Object.values(actions)"
            :key="`grid-header-mass-action-${button.id}`"
            href="#"
            @click.prevent="openModal(button)"
        >
            <i
                v-if="button.hasOwnProperty('icon') && button.icon.length"
                class="icon"
                :class="button.icon"
            />
            {{ button.label }}
        </a>
    </y-dropdown>
</template>

<script>

    import GlobalMixin from '@/mixins/Global';

    import { YDropdown } from '@deps';

    export default {

        name: 'GridHeaderMassActions',

        components: {
            YDropdown,
        },

        mixins: [GlobalMixin],

        props: {
            actions : Object,
            selected: Object,
        },

        computed: {
            /**
             * Check if any item is selected
             */
            hasSelected() {
                if (!this.selected || typeof this.selected !== 'object') {
                    return false;
                }

                return Object.values(this.selected).includes(1);
            },
        },

        methods: {
            /**
             * Open the modal in grid by id of modal
             *
             * @param button
             */
            openModal(button) {
                this.$refs.MassActionDropdown.close();
                const { params } = button.link;
                const ids = Object.keys(this.selected).filter((el) => this.selected[el] === 1);
                this.openGlobalModal(button.link.options.id, { ids, ...params });
            },
        },

    };
</script>
